import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  useColorModeValue,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import BenefitsImage from "../../images/benefits/benefits_image.png";

import BenefitsI from "../../images/benefits/benefits.jpg";
import BenefitsImg from "../../images/benefits/benefits_img.png";
const Feature = ({ text, title }) => {
  return (
    <Stack direction={"row"} align={"center"}>
      <Stack>
        <Text as={"span"} textAlign={"justify"}>
          {text}
        </Text>
      </Stack>
    </Stack>
  );
};

export default function Process() {
  return (
    <Container maxW={"7xl"} py={5} id={"process"} centerContent>
      <Flex justify={"center"}>
        <Image
          maxW={"100%"}
          alt={"benefits_pictobitte"}
          src={BenefitsI}
          objectFit={"scale-down"}
        />
      </Flex>
      <Grid
        templateRows={{ base: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(5, 1fr)" }}
        gap={2}
      >
        <GridItem colSpan={1} align={"center"} justify={"center"}>
          <Image
            pt={5}
            maxW={"50%"}
            alt={"benefits_img"}
            src={BenefitsImg}
            objectFit={"scale-down"}
          />
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 4, lg: 4 }}>
          <Stack>
            <Heading
              fontWeight={900}
              fontSize={{ base: "2xl", md: "4xl", lg: "5xl" }}
              pt={5}
              textAlign={"center"}
              lineHeight={"100%"}
              as={"span"}
              position={"relative"}
              _after={{
                content: "''",
                width: "full",
                height: "7%",
                position: "absolute",
                bottom: -2,
                left: 0,
                bg: "black",
                zIndex: -1,
              }}
            >
              BENEFITS OF CHOOSING OUR ARTWORK
            </Heading>
          </Stack>
        </GridItem>
      </Grid>

      <Text fontSize={"lg"} textAlign={"justify"} pt={3}>
        Experts with us understand the complexities related to the process of
        digital illustration creation services; they can provide lively
        eye-catching designs. These creative artists and animators are backed by
        a highly sophisticated infrastructure. Moreover they are well versed
        with all major digital illustration software tools. We deliver the
        desired artwork for you in great resolution and in the file format such
        as JPG, JPEG, PDF, EPS, TIFF etc., as per your demand. Further you enjoy
        the following benefits.
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} py={5}>
        <Flex justify={"center"}>
          <Image
            rounded={"md"}
            alt={"benefits_image_pictobitte"}
            src={BenefitsImage}
            objectFit={"contain"}
          />
        </Flex>
        <Stack spacing={5}>
          <Stack
            divider={
              <StackDivider
                borderColor={useColorModeValue("gray.100", "gray.700")}
              />
            }
          >
            <Feature
              text={
                "A no-compromise attitude on quality affirmation in spite of the project complexity or tautness of deadline."
              }
            />
            <Feature
              text={"Processes are tweaked to assure maximum cost savings."}
            />
            <Feature
              text={
                "Proven methods are used for keeping your confidential data highly secured."
              }
            />
            <Feature
              text={
                "We staff artists of international class who provide awe inspiring designs."
              }
            />
            <Feature
              text={
                "Providing creative design with unique illustration styles and techniques."
              }
            />
            <Feature
              text={
                "We provide complete Graphic Design Services including vector illustration services across the globe including UK, USA, Australia, Canada, Japan,  UAE and other Middle East countries supporting industries like advertising agencies, eLearning, publishing, gaming institutes, corporate house, fashion medicine, technology and more."
              }
            />
            <Feature
              text={
                "Now, do you want us to initiate the entire process or you have a rough sketch that just need to be refined? Well! As a professional illustration company, we would always love to assist you for any of your graphic related requirements."
              }
            />
          </Stack>
        </Stack>
      </SimpleGrid>
    </Container>
  );
}
