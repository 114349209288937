import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  useColorModeValue,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
  Box,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import ProcessImage from "../../images/process/process.png";
import ProcessI from "../../images/process/process.jpg";
import ProcessImg from "../../images/process/process_img.png";
const AccordionFeature = ({ title, text }) => {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <Text fontWeight={600}>{title}:</Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <Text as={"span"} textAlign={"justify"}>
          {text}
        </Text>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default function Process() {
  return (
    <Container maxW={"7xl"} py={5} id={"process"} centerContent>
      <Flex justify={"center"}>
        <Image
          maxW={"100%"}
          alt={"custom_art_pictobitte"}
          src={ProcessI}
          objectFit={"scale-down"}
        />
      </Flex>
      <Grid
        templateRows={{ base: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(5, 1fr)" }}
        gap={2}
      >
        <GridItem colSpan={1} align={"center"} justify={"center"}>
          <Image
            pt={5}
            maxW={"100%"}
            alt={"process_img"}
            src={ProcessImg}
            objectFit={"scale-down"}
          />
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 4, lg: 4 }}>
          <Stack>
            <Heading
              fontWeight={900}
              fontSize={{ base: "2xl", md: "4xl", lg: "5xl" }}
              pt={5}
              textAlign={"center"}
              lineHeight={"100%"}
              as={"span"}
              position={"relative"}
              _after={{
                content: "''",
                width: "full",
                height: "7%",
                position: "absolute",
                bottom: -2,
                left: 0,
                bg: "black",
                zIndex: -1,
              }}
            >
              PROCESS
            </Heading>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
              <Flex justify={"center"}>
                <Image
                  pt={5}
                  maxW={{ base: "xs", md: "2xs", lg: "xl" }}
                  rounded={"lg"}
                  alt={"process_image"}
                  src={ProcessImage}
                  objectFit={"scale-down"}
                />
              </Flex>
              <Stack spacing={5}>
                <Text fontSize={"lg"} pt={10}>
                  We have a strategic process for digital drawing in place to
                  make sure that what we offer assures maximum satisfaction to
                  the customers and add value to them. This also helps in
                  keeping our creative design pricing minimal.
                </Text>
                <Stack
                  spacing={4}
                  divider={
                    <StackDivider
                      borderColor={useColorModeValue("gray.100", "gray.700")}
                    />
                  }
                >
                  <Accordion allowMultiple>
                    <AccordionFeature
                      title={"Definition of project scope"}
                      text={
                        " We will discuss with you in detail to understand your graphic illustration design requirements and provide some of our sample sketches to fine-tune the expectations"
                      }
                    />
                    <AccordionFeature
                      title={"Transfer of files"}
                      text={
                        " You are furnished with our FTP details so as to facilitate safe file transfer. However, we also make you available with other options like cloud-based transfer, as you prefer."
                      }
                    />
                    <AccordionFeature
                      title={"Work allocation"}
                      text={
                        "After the description of product or the script is received, the work is allocated along with shrewd instructions to our team of well-equipped creative artists."
                      }
                    />
                    <AccordionFeature
                      title={"Drafting of sketch"}
                      text={
                        "We create rough hand sketches or line art illustrations."
                      }
                    />
                    <AccordionFeature
                      title={"Assurance of quality"}
                      text={
                        "The quality assurance team with us carries out multiple phases of quality checks for ensuring that the output generated is of great quality and is in line with the specific project guidelines."
                      }
                    />
                  </Accordion>
                </Stack>
              </Stack>
            </SimpleGrid>
          </Stack>
        </GridItem>
      </Grid>
    </Container>
  );
}
