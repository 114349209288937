export const clientsImgs = [
  {
    id: 1,
    image: require("../../images/clientes/320px-Capcom_logo-300x55.png"),
    alt: "capcom_logo",
  },
  {
    id: 2,
    image: require("../../images/clientes/artscase_logo.png"),
    alt: "artcase_logo",
  },
  {
    id: 3,
    image: require("../../images/clientes/Blizzard_logo.png"),
    alt: "blizzard_logo",
  },
  {
    id: 4,
    image: require("../../images/clientes/bubblegummers-300x200.png"),
    alt: "bubblegummers_logo",
  },
  {
    id: 5,
    image: require("../../images/clientes/Comiccon_logo.png"),
    alt: "comiccon_logo",
  },
  {
    id: 6,
    image: require("../../images/clientes/Discovery_Chanel_logo.png"),
    alt: "discovery_logo",
  },
  {
    id: 7,
    image: require("../../images/clientes/Disney_logo_n.png"),
    alt: "disney_logo",
  },
  {
    id: 8,
    image: require("../../images/clientes/hasbro_logo.png"),
    alt: "hasbro_logo",
  },
  {
    id: 9,
    image: require("../../images/clientes/marvel_studios_logo.png"),
    alt: "marvel_logo",
  },
  {
    id: 10,
    image: require("../../images/clientes/mentez_logo.png"),
    alt: "mentez_logo",
  },
  {
    id: 11,
    image: require("../../images/clientes/nescafe_logo.png"),
    alt: "nescafe_logo",
  },
  {
    id: 12,
    image: require("../../images/clientes/norma_editorial_logo.png"),
    alt: "norma_logo",
  },
  {
    id: 13,
    image: require("../../images/clientes/play-doh_logo.png"),
    alt: "playdoh_logo",
  },
  {
    id: 14,
    image: require("../../images/clientes/redbubble_logo-300x83.png"),
    alt: "redbubble_logo",
  },
  {
    id: 15,
    image: require("../../images/clientes/Santillana_logo-300x93.png"),
    alt: "santillana_logo",
  },
  {
    id: 16,
    image: require("../../images/clientes/star_wars_logo.png"),
    alt: "starWars_logo",
  },
  {
    id: 17,
    image: require("../../images/clientes/TeepublicIcon-e1558115314802.png"),
    alt: "teePublic_logo",
  },
];
