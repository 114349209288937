export const porfolioImgs = [
  {
    id: 1,
    img: require("../../images/porfolio/Adventure.jpg"),
    alt: "adventure",
  },
  { id: 2, img: require("../../images/porfolio/Animal.jpg"), alt: "animal" },
  { id: 3, img: require("../../images/porfolio/Beast.jpg"), alt: "Beast" },
  { id: 4, img: require("../../images/porfolio/Boo.jpg"), alt: "Boo" },
  { id: 5, img: require("../../images/porfolio/Conejo.jpg"), alt: "Conejo" },
  {
    id: 6,
    img: require("../../images/porfolio/Crematoria.jpg"),
    alt: "Crematoria",
  },
  {
    id: 7,
    img: require("../../images/porfolio/Daredevil.jpg"),
    alt: "Daredevil",
  },
  {
    id: 8,
    img: require("../../images/porfolio/DC_Bad_Guys.jpg"),
    alt: "DC_Bad_Guys",
  },
  {
    id: 9,
    img: require("../../images/porfolio/DC_Good_Guys.jpg"),
    alt: "DC_Good_Guys",
  },
  {
    id: 10,
    img: require("../../images/porfolio/Final.jpg"),
    alt: "Final",
  },
  {
    id: 11,
    img: require("../../images/porfolio/Finala.jpg"),
    alt: "Finala",
  },
  {
    id: 12,
    img: require("../../images/porfolio/Finalisimo.jpg"),
    alt: "Finalisimo",
  },
  {
    id: 13,
    img: require("../../images/porfolio/FinalPAnda.jpg"),
    alt: "FinalPAnda",
  },
  {
    id: 14,
    img: require("../../images/porfolio/Foo.jpg"),
    alt: "Foo",
  },
  {
    id: 15,
    img: require("../../images/porfolio/Fruits_Ivan_Camelo.jpg"),
    alt: "Fruits_Ivan_Camelo",
  },
  {
    id: 16,
    img: require("../../images/porfolio/Hellboy.jpg"),
    alt: "Hellboy",
  },
  {
    id: 17,
    img: require("../../images/porfolio/hulkie_by_pictobitte-d3coqnr.jpg"),
    alt: "Hulkie_by_pictobitte-d3coqnr",
  },
  {
    id: 18,
    img: require("../../images/porfolio/HulkZ.jpg"),
    alt: "HulkZ",
  },
  {
    id: 19,
    img: require("../../images/porfolio/Imperator.jpg"),
    alt: "Imperator",
  },
  {
    id: 20,
    img: require("../../images/porfolio/Joker_and_Harley.jpg"),
    alt: "Joker_and_Harley",
  },
  {
    id: 21,
    img: require("../../images/porfolio/krang.jpg"),
    alt: "krang",
  },
  {
    id: 22,
    img: require("../../images/porfolio/Kylo.jpg"),
    alt: "Kylo",
  },
  {
    id: 23,
    img: require("../../images/porfolio/LastU.jpg"),
    alt: "LastU",
  },
  {
    id: 24,
    img: require("../../images/porfolio/Leia.jpg"),
    alt: "Leia",
  },
  {
    id: 25,
    img: require("../../images/porfolio/Lunatacks.jpg"),
    alt: "Lunatacks",
  },
  {
    id: 26,
    img: require("../../images/porfolio/magnus.jpg"),
    alt: "magnus",
  },
  {
    id: 27,
    img: require("../../images/porfolio/Marvel_Bad_Guys.jpg"),
    alt: "Marvel_Bad_Guys",
  },
  {
    id: 28,
    img: require("../../images/porfolio/Marvel_Good_Guys.jpg"),
    alt: "Marvel_Good_Guys",
  },
  {
    id: 29,
    img: require("../../images/porfolio/Maul.jpg"),
    alt: "Maul",
  },
  {
    id: 30,
    img: require("../../images/porfolio/Mr_Skull.jpg"),
    alt: "Mr_Skull",
  },
  {
    id: 31,
    img: require("../../images/porfolio/Muppets.jpg"),
    alt: "Muppets",
  },
  {
    id: 32,
    img: require("../../images/porfolio/Mutantes.jpg"),
    alt: "Mutantes",
  },
  {
    id: 32,
    img: require("../../images/porfolio/Mutants.jpg"),
    alt: "Mutants",
  },
  {
    id: 33,
    img: require("../../images/porfolio/picolo.jpg"),
    alt: "picolo",
  },
  {
    id: 34,
    img: require("../../images/porfolio/Poster.jpg"),
    alt: "Poster",
  },
  {
    id: 35,
    img: require("../../images/porfolio/Poster1.jpg"),
    alt: "Poster1",
  },
  {
    id: 36,
    img: require("../../images/porfolio/Poster2.jpg"),
    alt: "Poster2",
  },
  {
    id: 37,
    img: require("../../images/porfolio/Princess1.jpg"),
    alt: "Princess1",
  },
  {
    id: 38,
    img: require("../../images/porfolio/Princess2.jpg"),
    alt: "Princess2",
  },
  {
    id: 39,
    img: require("../../images/porfolio/Princess3.jpg"),
    alt: "Princess3",
  },
  {
    id: 40,
    img: require("../../images/porfolio/Princess4.jpg"),
    alt: "Princess4",
  },
  {
    id: 41,
    img: require("../../images/porfolio/Princess5.jpg"),
    alt: "Princess5",
  },
  {
    id: 42,
    img: require("../../images/porfolio/Punisher.jpg"),
    alt: "Punisher",
  },
  {
    id: 43,
    img: require("../../images/porfolio/Red_Genie.jpg"),
    alt: "Red_Genie",
  },
  {
    id: 44,
    img: require("../../images/porfolio/Redpanda.jpg"),
    alt: "Redpanda",
  },
  {
    id: 45,
    img: require("../../images/porfolio/Rocket.jpg"),
    alt: "Rocket",
  },
  {
    id: 46,
    img: require("../../images/porfolio/Rogueh.jpg"),
    alt: "Rogueh",
  },
  {
    id: 47,
    img: require("../../images/porfolio/Street_Fighter.png"),
    alt: "Street_Fighter",
  },
  {
    id: 48,
    img: require("../../images/porfolio/Street_Fighter2.png"),
    alt: "Street_Fighter2",
  },
  {
    id: 49,
    img: require("../../images/porfolio/Street_Fighter3.png"),
    alt: "Street_Fighter3",
  },
  {
    id: 50,
    img: require("../../images/porfolio/RR.png"),
    alt: "RR",
  },
  {
    id: 51,
    img: require("../../images/porfolio/Screen Shot 2013-08-30 at 12.31.23 PM.png"),
    alt: "Rocket",
  },
  {
    id: 52,
    img: require("../../images/porfolio/Screen Shot 2018-09-28 at 11.58.58 AM.png"),
    alt: "Rocket",
  },
  {
    id: 53,
    img: require("../../images/porfolio/SW.png"),
    alt: "SW",
  },
  {
    id: 54,
    img: require("../../images/porfolio/TF.png"),
    alt: "TF",
  },
  {
    id: 55,
    img: require("../../images/porfolio/Screen Shot 2018-09-28 at 12.05.03 PM.png"),
    alt: "Rocket",
  },
  {
    id: 56,
    img: require("../../images/porfolio/Screen Shot 2018-09-28 at 12.10.04 PM.png"),
    alt: "Rocket",
  },
  {
    id: 57,
    img: require("../../images/porfolio/Screen Shot 2018-09-28 at 12.11.14 PM.png"),
    alt: "Rocket",
  },
  {
    id: 58,
    img: require("../../images/porfolio/Screen Shot 2018-09-28 at 12.26.01 PM.png"),
    alt: "Rocket",
  },
  {
    id: 59,
    img: require("../../images/porfolio/Screen Shot 2018-09-28 at 12.27.34 PM.png"),
    alt: "Rocket",
  },
  {
    id: 60,
    img: require("../../images/porfolio/Screen Shot 2018-09-28 at 12.27.53 PM.png"),
    alt: "Rocket",
  },
  {
    id: 61,
    img: require("../../images/porfolio/Shingeki.jpg"),
    alt: "Shingeki",
  },
  {
    id: 62,
    img: require("../../images/porfolio/Sketch.jpg"),
    alt: "Sketch",
  },
  {
    id: 63,
    img: require("../../images/porfolio/skullkat1b.jpg"),
    alt: "skullkat1b",
  },
  {
    id: 64,
    img: require("../../images/porfolio/StarWars.jpg"),
    alt: "StarWars",
  },
  {
    id: 65,
    img: require("../../images/porfolio/supeFINAL.jpg"),
    alt: "supeFINAL",
  },
  {
    id: 66,
    img: require("../../images/porfolio/Thor.jpg"),
    alt: "Thor",
  },
  {
    id: 67,
    img: require("../../images/porfolio/Thundercats.jpg"),
    alt: "Thundercats",
  },
  {
    id: 68,
    img: require("../../images/porfolio/Ultron.jpg"),
    alt: "Ultron",
  },
  {
    id: 69,
    img: require("../../images/porfolio/Villains_Compo_Final.jpg"),
    alt: "Villains_Compo_Final",
  },
  {
    id: 70,
    img: require("../../images/porfolio/Zelda.jpg"),
    alt: "Zelda",
  },
];
