import {
  Box,
  Container,
  Image,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
//import { FaInstagram, FaTwitter, FaFacebook, FaBehance } from "react-icons/fa";
import LogoPictobitte from "../../images/logo_pictobitte.png";
import { Link as ReachLink } from "react-scroll";

const Logo = (props) => {
  return (
    <Image
      w={150}
      h={10}
      alt={"vanacmelot_logo"}
      objectFit={"cover"}
      src={LogoPictobitte}
    />
  );
};

// const SocialButton = ({ children, label, href }) => {
//   return (
//     <chakra.button
//       bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
//       rounded={"full"}
//       w={8}
//       h={8}
//       cursor={"pointer"}
//       as={"a"}
//       href={href}
//       display={"inline-flex"}
//       alignItems={"center"}
//       justifyContent={"center"}
//       transition={"background 0.3s ease"}
//       _hover={{
//         bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
//       }}
//     >
//       <VisuallyHidden>{label}</VisuallyHidden>
//       {children}
//     </chakra.button>
//   );
// };

export default function SmallCentered() {
  let date = new Date();
  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        spacing={4}
        justify={"center"}
        align={"center"}
      >
        <Logo />
        <Stack direction={"row"} spacing={6}>
          <Link as={ReachLink} to="about">
            About
          </Link>
          <Link as={ReachLink} to="services">
            Services
          </Link>
          <Link as={ReachLink} to="ourClients">
            Clients
          </Link>
          <Link as={ReachLink} to="contact">
            Contact
          </Link>
        </Stack>
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.700")}
      >
        <Container
          as={Stack}
          maxW={"6xl"}
          py={4}
          direction={{ base: "column", md: "row" }}
          spacing={4}
          justify={{ base: "center", md: "space-between" }}
          align={{ base: "center", md: "center" }}
        >
          <Text>© {date.getFullYear()} PICTOBITTE All rights reserved</Text>
          {/* <Stack direction={"row"} spacing={6}>
            <SocialButton
              label={"Facebook"}
              href={"https://www.facebook.com/"}
            >
              <FaFacebook />
            </SocialButton>
            <SocialButton
              label={"Twitter"}
              href={"https://twitter.com/"}
            >
              <FaTwitter />
            </SocialButton>
            <SocialButton
              label={"Behance"}
              href={"https://www.behance.net/"}
            >
              <FaBehance />
            </SocialButton>
            <SocialButton
              label={"Instagram"}
              href={"https://www.instagram.com/"}
            >
              <FaInstagram />
            </SocialButton>
          </Stack> */}
        </Container>
      </Box>
    </Box>
  );
}
