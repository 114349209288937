import "./App.css";
import Home from "./pages/index";
import PorfolioPage from "./pages/porfolio";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { ParallaxProvider } from "react-scroll-parallax";

function App() {
  return (
    <ChakraProvider>
      <ParallaxProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/porfolio" element={<PorfolioPage />} exact />
          </Routes>
        </Router>
      </ParallaxProvider>
    </ChakraProvider>
  );
}

export default App;
