import {
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  Container,
  SimpleGrid,
  Center,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import CustomI from "../../../images/cillustration/custom_illustration.jpg";
import DIllustrationImg from "../../../images/cillustration/custom_illustration_img.png";

export default function Services({ img, alt, topLine, headline, description }) {
  return (
    <Container maxW={"7xl"} py={5} id={"services"} centerContent>
      <Flex justify={"center"}>
        <Image
          maxW={"100%"}
          alt={"custom_illustration_pictobitte"}
          src={CustomI}
          objectFit={"scale-down"}
        />
      </Flex>
      <Grid
        templateRows={{ base: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(5, 1fr)" }}
        gap={2}
      >
        <GridItem colSpan={1} align={"center"} justify={"center"}>
          <Image
            pt={5}
            maxW={"100%"}
            alt={"custom_illutration_img"}
            src={DIllustrationImg}
            objectFit={"scale-down"}
          />
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 4, lg: 4 }}>
          <Stack>
            <Heading
              fontWeight={900}
              pt={5}
              fontSize={{ base: "2xl", md: "4xl", lg: "5xl" }}
              textAlign={"center"}
              lineHeight={"100%"}
              as={"span"}
              position={"relative"}
              _after={{
                content: "''",
                width: "full",
                height: "7%",
                position: "absolute",
                bottom: -2,
                left: 0,
                bg: "black",
                zIndex: -1,
              }}
            >
              {topLine}
            </Heading>
            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              spacing={"20px"}
              py={4}
              centerContent
            >
              <Stack spacing={5}>
                <Heading textAlign={"center"} as={"span"}>
                  {headline}
                </Heading>
                <Text
                  fontSize={"lg"}
                  textAlign={"justify"}
                  align={Center}
                  justifyContent={"center"}
                >
                  {description}
                </Text>
              </Stack>
              <Flex justify={"center"}>
                <Image alt={alt} src={img} objectFit={"contain"} />
              </Flex>
            </SimpleGrid>
          </Stack>
        </GridItem>
      </Grid>
    </Container>
  );
}
