import {
  Container,
  Heading,
  Grid,
  Stack,
  Text,
  Image,
  GridItem,
} from "@chakra-ui/react";
import AboutImage from "../../images/about/about_image.png";

export default function CallToActionWithIllustration() {
  return (
    <Container maxW={"7xl"} py={2} id={"about"}>
      <Grid
        templateRows={{ base: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(5, 1fr)" }}
        gap={2}
      >
        <GridItem colSpan={1} align={"center"} justify={"center"}>
          <Image
            maxW={"100%"}
            alt={"about_pictobitte_image"}
            src={AboutImage}
            objectFit={"scale-down"}
          />
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 4, lg: 4 }}>
          <Stack>
            <Heading
              fontWeight={900}
              fontSize={{ base: "2xl", md: "4xl", lg: "5xl" }}
              textAlign={"center"}
              lineHeight={"100%"}
              as={"span"}
              position={"relative"}
              _after={{
                content: "''",
                width: "full",
                height: "7%",
                position: "absolute",
                bottom: -2,
                left: 0,
                bg: "black",
                zIndex: -1,
              }}
            >
              WHO WE ARE AND WHAT WE DO !!!
            </Heading>
            <Text
              py={5}
              color={"gray.800"}
              fontSize={{ base: "sm", md: "md", lg: "lg" }}
              textAlign="justify"
            >
              PICTOBITTE is a premier illustration company with specialization
              in creation of Fabric design, Patterns, Panels, character design
              and Custom Art. We have extensive experience in working with a
              broad spectrum of media platforms. Our expertise in the field of
              illustration extends to game illustration, Animation, cartoon
              illustration, Toys Design, technical illustration and digital
              illustration. We are professional, flexible and programmed to
              deliver complete customer satisfaction. And all of this at an
              unbeatable price!
            </Text>
          </Stack>
        </GridItem>
      </Grid>
    </Container>
  );
}
