import { Flex } from "@chakra-ui/react";
import hero_pictobitte from "../../images/hero/hero.png";

export default function WithBackgroundImage() {
  return (
    <Flex
      w={"full"}
      h={{ base: "50vh", md: "70vh", xl: "90vh" }}
      justify={"center"}
      bgPosition="revert center"
      bgRepeat="no-repeat"
      bgSize="cover"
      backgroundImage={hero_pictobitte}
    />
  );
}
