import {
  Box,
  SimpleGrid,
  Stack,
  Flex,
  Container,
  Image,
  Heading,
  Center,
  Text,
} from "@chakra-ui/react";

import { clientsImgs } from "./Data";

const Feature = ({ img, alt }) => {
  return (
    <Stack>
      <Flex w={150} h={150} align={"center"} justify={"center"}>
        <Image src={img} alt={alt} />
      </Flex>
    </Stack>
  );
};

export default function Clients() {
  return (
    <Container maxW={"8xl"} py={10} id={"ourClients"} background={"#D0D3D4"}>
      <Heading fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}>
        <Center>
          <Text
            as={"span"}
            position={"relative"}
            justifyItems={"center"}
            textColor={"#FFF"}
          >
            OUR CLIENTS
          </Text>
        </Center>
        <br />
      </Heading>
      <Box p={1}>
        <SimpleGrid columns={{ base: 2, md: 6 }} spacing={10}>
          {clientsImgs.map((item, index) => {
            return <Feature key={index} img={item.image} alt={item.alt} />;
          })}
        </SimpleGrid>
      </Box>
    </Container>
  );
}
