import { Container, SimpleGrid, Image, Flex } from "@chakra-ui/react";
import React from "react";
import { porfolioImgs } from "../Porfolio/Data";

const Galery = ({ img, alt }) => {
  return (
    <Flex maxW={"100%"} align={"center"} justify={"center"}>
      <Image
        borderRadius="10%"
        maxW={"100%"}
        src={img}
        alt={alt}
        objectFit={"scale-down"}
      />
    </Flex>
  );
};

function Porfolio() {
  return (
    <Container maxW={"7xl"} py={5}>
      <SimpleGrid columns={{ base: 2, md: 5 }} spacing={2}>
        {porfolioImgs.map((item, index) => {
          return <Galery key={index} img={item.img} alt={item.alt} />;
        })}
      </SimpleGrid>
    </Container>
  );
}

export default Porfolio;
