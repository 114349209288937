import {
  Container,
  Flex,
  Box,
  Heading,
  Button,
  VStack,
  Center,
  IconButton,
  HStack,
  Text,
} from "@chakra-ui/react";
import { BsWhatsapp } from "react-icons/bs";
import { MdEmail, MdPhone } from "react-icons/md";
//import { BsFacebook, BsInstagram, BsBehance } from "react-icons/bs";
//import reCAPTCHA from "react-google-recaptcha";

const SocialLink = ({ url, socialIcon, label }) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <IconButton
        aria-label={label}
        variant="ghost"
        size="lg"
        isRound={true}
        _hover={{ bg: "#0D74FF" }}
        icon={socialIcon}
      />
    </a>
  );
};

export default function contact() {
  return (
    <Container
      bg="rgba(0,0,0,0.5)"
      maxW="full"
      mt={0}
      centerContent
      overflow="hidden"
      id="contact"
    >
      <Flex p={2}>
        <Box
          bg="#D0D3D4"
          color="white"
          borderRadius="lg"
          m={{ sm: 4, md: 16, lg: 10 }}
          p={{ sm: 5, md: 5, lg: 16 }}
        >
          <Box>
            <Center>
              <Heading fontSize={{ base: "2xl", md: "4xl", lg: "5xl" }}>
                CONTACT PICTOBITTE
              </Heading>
            </Center>
            <Center>
              <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                <VStack pl={0} spacing={3} alignItems="center">
                  <Button
                    size="md"
                    variant="ghost"
                    color="#000"
                    leftIcon={<MdEmail color="#1970F1" size="20px" />}
                  >
                    <a href="mailto:info@pictobitte.com">info@pictobitte.com</a>
                  </Button>
                  <Text
                    size="md"
                    variant="ghost"
                    color="#000"
                    leftIcon={<MdPhone color="#1970F1" size="20px" />}
                  >
                    (+1) 7868226376
                  </Text>
                  <Heading
                    p={2}
                    as={"h4"}
                    size="sm"
                    color={"blackAlpha.700"}
                    textAlign={"center"}
                  >
                    1900 N Bayshore Dr., Suite 1A # 136-1147
                  </Heading>
                  <Heading
                    p={2}
                    as={"h4"}
                    size="sm"
                    color={"blackAlpha.700"}
                    textAlign={"center"}
                  >
                    Miami, florida, 33132
                  </Heading>
                  <Heading p={2} as={"h4"} size="sm" color={"blackAlpha.700"}>
                    United State of America
                  </Heading>
                </VStack>
              </Box>
              <reCAPTCHA />
            </Center>

            <Center>
              <HStack
                mt={{ lg: 5, md: 5 }}
                spacing={5}
                p={2}
                alignItems="flex-start"
              >
                {/* <SocialLink
                  url={"https://www.behance.net/"}
                  socialIcon={<BsBehance size="28px" />}
                  label={"Behance"}
                />
                <SocialLink
                  url={"https://www.instagram.com/"}
                  socialIcon={<BsInstagram size="28px" />}
                  label={"Instagram"}
                /> */}
                <SocialLink
                  url={"https://wa.me/+17868226376"}
                  socialIcon={<BsWhatsapp size="28px" />}
                  label={"WhatsApp"}
                />
              </HStack>
            </Center>
          </Box>
        </Box>
      </Flex>
    </Container>
  );
}
