import React, { useState } from "react";
import CIllustration from "../components/Services/CIllustration";
import DIllustration from "../components/Services/DIllustration";
import { cIllustration, dIllustration } from "../components/Services/Data";
import Benefits from "../components/Benefits";
import NavbarHome from "../components/Navbar/NavBarHome";
import SidebarHome from "../components/Sidebar/SideBarHome";
import About from "../components/AboutSection";
import Process from "../components/Process";
import Clients from "../components/Clients";
import Contact from "../components/Contact";
import Hero from "../components/HeroSection";
import Footer from "../components/Footer";
import { FloatingWhatsApp } from "react-floating-whatsapp-button";
import "react-floating-whatsapp-button/dist/index.css";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <SidebarHome isOpen={isOpen} toggle={toggle} />
      <NavbarHome toggle={toggle} />
      <Hero />
      <About />
      <CIllustration {...cIllustration} />
      <DIllustration {...dIllustration} />
      <Process />
      <Benefits />
      <Clients />
      <Contact />
      <Footer />
      <FloatingWhatsApp phone={"+17868226376"} autoOpenTimeout={15000} />
    </>
  );
};

export default Home;
