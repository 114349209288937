export const cIllustration = {
  id: "about",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "CUSTOM ILLUSTRATION",
  headline: "Stand out with custom illustration",
  description:
    `In a world that is overly saturated with stock photography, custom illustration can really stand out. It can do many jobs too. It has the power to tell a complex story. It can bring boring data to life. It can create a one-of-a-kind brand and provide plenty of room to creatively grow. \n\n` +
    `At PICTOBITTE Design, we strive to accommodate our clients needs, and illustration is one service that we are truly proud to offer. It is very specialized and the ability to do it well and have the skill to incorporate it is rare.`,
  buttonLabel: "",
  imgStart: true,
  img: require("../../images/cillustration/custom_illustration_image.png"),
  alt: "custom_Illustration_pictobitte",
  dark: true,
  primary: true,
  darkText: true,
};

export const dIllustration = {
  id: "digital",
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "DIGITAL ILLUSTRATION",
  headline: "",
  description: `Our illustrators are skilled artists who create technical and nontechnical artwork for print media, digital media and commercial applications. With strong traditional art skills and access to the latest digital illustration technology, our illustrators can produce stunning artwork and line drawings as dictated by your needs. We have the technical knowledge and experience to create visuals for stories, concepts or ideas for publications. We can also design graphics for commercial use in packaging, calendars, cards and stationary. Our client list includes publishing houses, advertising firms, media companies and clients around the world.`,
  buttonLabel: "",
  imgStart: false,
  img: require("../../images/dillustration/digital_illustration.png"),
  alt: "digital_illustration",
  dark: true,
  primary: false,
  darkText: true,
};
