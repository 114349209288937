import React, { useState } from "react";
import NavBarPorfolio from "../components/Navbar/NavBarPorfolio/index";
import SideBarPorfolio from "../components/Sidebar/SideBarPorfolio/index";
import Porfolio from "../components/Porfolio";

const PorfolioPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <SideBarPorfolio isOpen={isOpen} toggle={toggle} />
      <NavBarPorfolio toggle={toggle} />
      <Porfolio />
    </>
  );
};

export default PorfolioPage;
